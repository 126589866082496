$colors: (
    primary: (
        base: #022b57,
        dark: #011a3d,
    ),
    neutral: (
        xlight: #fff,
        light: mix(#ecf6ff, #f2f2f2, 65%),
        base: #bed8e9,
        dark: #001633,
    ),
    status: (
        warning: #d22d30,
        info: #f4d157,
        success: #53bc64,
        other: #9b51e0,
        orange: #f49957,
    ),
);

$color-light: color(neutral, xlight);
$color-dark: color(neutral, dark);

$color-card-text: rgba(black, 0.9);
$color-card-muted: rgba(black, 0.7);

// Specific colors.
// Variables are there to be reused, so being  to specific or creating to many will create the opposite situation
$color-background: color(neutral, light);
$color-text: $color-dark;
$color-button-text: color(primary);
$color-label: rgba(color(primary, dark), 0.8);
$color-muted: rgba($color-text, 0.65);
$color-muted--strong: rgba($color-text, 0.35);
$color-links: $color-text;
$color-links--hover: $color-text;
$color-outline: rgba(color(neutral), 0.6);
$color-backdrop: rgba($color-background, 0.8);
$color-border: tint(black, 90%);
$color-border--strong: tint(black, 72%);
$color-warning-muted: rgba(color(status, warning), 0.1);

.color--muted {
    color: $color-muted
}
