@import "./functions";

@font-face {
  font-family: "Unica77";
  src: local("Unica77"),
    url("../../assets/fonts/unica77/unica77-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Unica77";
  src: local("Unica77"),
    url("../../assets/fonts/unica77/unica77-medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

.remin-theme {
  --font-family: "Unica77", Helvetica,sans-serif;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--root-font-size);
  position: relative;
  color: #001633;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-font-feature-settings: "calt";
  font-feature-settings: "calt";
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  text-rendering: optimizeLegibility;

  // Variables
  // Since remin-theme will be included in other apps, we can't base our
  // measurements on rem values.
  --root-font-size: 16px;

  // Fluid typography calculator
  // https://modern-fluid-typography.vercel.app/

  :where(h1, .h1) {
    // https://modern-fluid-typography.vercel.app?rootFontSize=16&minSize=36&fluidSize=2.6&relativeSize=1.6&maxSize=48

    // This needs to be broken up due to an old version of postcss-preset-env
    // https://github.com/csstools/postcss-preset-env/issues/163#issuecomment-679057151
    --font-size-min: rem(2.25);
    --font-size: calc(2.6vw + rem(1.6));
    --font-size-max: rem(3);
    font-size: clamp(var(--font-size-min), var(--font-size), var(--font-size-max));
    line-height: 1.1;
  }

  :where(h2, .h2) {
    font-size: rem(2);
    // https://modern-fluid-typography.vercel.app?rootFontSize=16&minSize=24&fluidSize=0.9&relativeSize=1.5&maxSize=32

    --font-size-min: rem(1.5);
    --font-size: calc(0.9vw + rem(1.5));
    --font-size-max: rem(2);
    font-size: clamp(var(--font-size-min), var(--font-size), var(--font-size-max));
    line-height: 1.2;
  }

  :where(h3, .h3) {
    font-size: rem(1.6);
    // https://modern-fluid-typography.vercel.app?rootFontSize=16&minSize=20&fluidSize=1.2&relativeSize=1&maxSize=25.6

    --font-size-min: rem(1.25);
    --font-size: calc(1.2vw + rem(1));
    --font-size-max: rem(1.6);
    font-size: clamp(var(--font-size-min), var(--font-size), var(--font-size-max));
    line-height: 1.2;
  }

  :where(h4, .h4) {
  font-size: rem(1.2);
  line-height: 1.2;
  }

  :where(p) {
    font-size: rem(1);
    line-height: 1.45;
  }

  :where(small, .small) {
    font-size: rem(0.8);
  }

  // Helpers
  :where(.link) {
    text-decoration: underline;
  }
}
