@import "../scss/functions";


.modal {
    $this: &;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    overflow: hidden;


    &:where(input) {
        border: 1px solid rgba(54, 91, 143, 0.3);
        padding: 10px;
        border-radius: 4px;
        width: 100%;
    }

    input:placeholder-shown {
        text-overflow: ellipsis;
    }

    &:where(h3) {
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 17px;
        color: rgba(84, 96, 114, 0.85);
    }

    &--exit {
        #{$this}__content {
            animation: modal-exit 200ms ease-out both;
        }
    }

    &__top-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        flex: 0 0 auto;
        z-index: 2;
        min-height: 38px;

        button {
            padding: 5px;
            flex: 0 0 auto;
            position: relative;
            opacity: 0.6;
            border-radius: 4px;
            margin: 5px;
            line-height: 13px;

            &:hover {
                background: var(--color-alt-background);
                opacity: 1;
            }
        }
    }

    &__button-bar {
        position: absolute;
        right: 0;
    }

    &__bottom-bar {
        flex: 0 0 auto;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 5px;
        // Prevent scrollable content to overlap
        z-index: 1;
    }

    &__content {
        max-height: 100%;
        z-index: 501;
        min-width: calc(rem(24) - #{(20px * 1.5)}); // TODO: Why 1.5?
        border-radius: 8px;
        background-color: var(--color-light);
        filter: drop-shadow(0px 9px 13px rgba(19, 48, 84, 0.10)) drop-shadow(0px 3.93945px 6.76474px rgba(19, 48, 84, 0.05));
        animation: modal-enter 300ms var(--transition-ease-out-expo) both;
        position: relative;
        background: white;
    }

    &__content--padding {
        padding: rem(1.5);
        padding-top: 50px;
    }

    &__title {
        font-weight: bold;
        font-size: 16px;
        color: var(--color-primary);
        text-align: center;
        flex: 1 1 auto;
        margin: 0;
    }

    &__close {
        z-index: 1;
        padding: 15px;
        position: absolute;
        right: 0;
    }

    @keyframes modal-enter {
        from {
            opacity: 0;
            transform: translateY(rem(5));
        }
    }

    @keyframes modal-exit {
        to {
            opacity: 0;
            transform: translateY(rem(2));
        }
    }
}

.modal-content {
    padding: 25px;
    overflow: hidden;
    padding-top: 0;

    input,
    textarea {
        border: 1px solid rgba(54, 91, 143, 0.3);
        padding: 10px;
        border-radius: 4px;
        width: 100%;
    }

    input:placeholder-shown textarea:placeholder-shown {
        text-overflow: ellipsis;
    }
}