html {
    font-size: #{$font-size-base}px;

    @media only print {
        font-size: #{$font-size-print}px;
    }
}

// Print settings
@page {
    size: auto;
    margin: 5mm;
}
