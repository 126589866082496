body {
    // Type
    @include font(base, normal);
    @include font-size(base);
    // letter-spacing: -0.04em;
    // word-spacing: -0.01em;

    position: relative;

    color: $color-text;
    font-style: normal;
    // font-variant-numeric: tabular-nums;
    overflow-x: hidden;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-variant-ligatures: contextual;
    text-rendering: optimizeLegibility;
    // -webkit-font-variant-ligatures: contextual;
    // -webkit-text-rendering: optimizeLegibility;

    background-color: $color-background;

    &.block-scrolling {
        overflow: hidden;
        overscroll-behavior: contain;
    }
}
