a {
    @include font(base, normal);
    cursor: pointer;

    color: $color-links;

    &:not([class]) {
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        color: $color-links--hover;
    }
}
