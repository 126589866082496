
@font-face {
    font-family: 'Unica77';
    src: local('Unica77'), url('../../assets/fonts/unica77/unica77-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Unica77';
    src: local('Unica77'), url('../../assets/fonts/unica77/unica77-medium.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppings'), url('../../assets/fonts/poppins/poppins-semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppings'), url('../../assets/fonts/poppins/poppins-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppings'), url('../../assets/fonts/poppins/poppins-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}


