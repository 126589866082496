.remin-theme {
  // Generic spacing - based on Fibonacci
  // Can't use rem values since we are including the lib in external pages
  --space-unit: 16px;
  --space-1: calc(0.125 * var(--space-unit));
  --space-2: calc(0.25 * var(--space-unit));
  --space-3: calc(0.5 * var(--space-unit));
  --space-4: calc(0.75 * var(--space-unit));
  --space-5: calc(1.25 * var(--space-unit));
  --space-6: calc(2 * var(--space-unit));
  --space-7: calc(3.25 * var(--space-unit));
  --space-8: calc(5.25 * var(--space-unit));
  --space-9: calc(8.5 * var(--space-unit));

  // Example of responsive spacing
  // @media (max-width: 800px) {
  //   :root {
  //     --space-unit: rem(1.5);
  //   }
  // }

  // Colors
  --color-blue--light: #eef5fa;
  --color-blue: #bed8e9;
  --color-blue--hover: #abcde3;
  --color-blue--dark: #022b57;
  --color-blue--darker: #011a3d;
  --color-blue-gray: #606e88;
  --color-gray--light: #e6e6e6;
  --color-gray: #666666;
  --color-white: #eef5fa;
  --color-red: #d22d30;
  --color-green: #53bc64;
  --color-yellow: #f4d157;
  --color-light: #fff;

  --color-text-primary: var(--color-blue--darker);
  --color-text-alt-primary: var(--color-blue--dark);
  --color-primary: var(--color-blue--dark);
  --color-primary-hover: var(--color-blue--darker);
  --color-secondary-hover: var(--color-blue--hover);
  --color-button-secondary: var(--color-blue);

  // Focus state
  --color-primary-outline: rgba(2, 43, 87, 0.75);

  --color-border: var(--color-blue--dark);
  --color-border-active: var(--color-blue--dark);
  --color-border-active-alt: var(--color-blue--dark);

  // Border
  --border: 1px solid #abbedf;

  // Border radius
  --border-radius: 4px;
  --border-radius--huge: 100px;

  // Transitions
  --transition-ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --transition-ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);

  // Font size
  --font-size-enlarged: 110%;

  // Gutter
  --gutter: var(--space-6);
  @media (max-width: 600px) {
    --gutter: var(--space-5);
  }
}
